import React, { useEffect, useState, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import axios from 'axios';
import DataTable from "react-data-table-component";
import { env } from './const';
import { headers } from '../utils/common';
import { getRole } from '../utils/common';
import { Role } from '../utils/role';
import DeactivateFarm from './DeactivateFarm';
import { Modal, Button } from 'react-bootstrap';
import { getUserName } from '../utils/common';
import { useLoading, Bars } from '@agney/react-loading';
import * as axioInstance from '../utils/axiosinstace';
import GrowthCurveComponent from "./GrowthCurveComponent";
import { AiFillFilePdf } from "react-icons/ai";
import moment from "moment";
import $ from 'jquery';
import 'select2';
const DailyOperationsReport = () => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />,
    });
    const { containerProps: containerProps2, indicatorEl: indicatorEl2 } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />,
    });

    const [farmData, setFarmData] = useState([]);
    const [batchData, setBatchData] = useState([]);
    const [headersobj] = useState(headers());
    const [graphData, setGraphData] = useState({});
    const [showGraph, setShowGraph] = useState(false);
    const [showLoader, setShowloader] = useState(false);
    const [showLoader2, setShowloader2] = useState(false);

    const { register, handleSubmit, watch, formState: { errors }, setValue, trigger } = useForm({
        mode: 'onChange'
    });
    const onchangeHandler = async (e) => {

        try {
            setShowData(true);
            const response = await axioInstance.getListofBatches(e.target.value, 'full');
            setBatchData(response.list);
            setShowGraph(false);
        } catch (err) {
            setBatchData([]);
            console.log(err);
        }
    }
    const [xaxis, setXaxis] = useState([]);
    const [yaxis, setYaxis] = useState([]);
    const [idleweights, seIdleweights] = useState([]);
    const [showData, setShowData] = useState(true);
    const [maleSeries, setMaleSeries] = useState([]);
    const [femaleSeries, setFemaleSeries] = useState([]);
    const [asHatchedSeries, setAsHatchedSeries] = useState([]);
    const [sexingStatus, setSexingStatus] = useState('no');
    const [data, setData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [maleLegends, setMaleLegends] = useState([]);
    const [femaleLegends, setFemaleLegends] = useState([]);
    const [chickLegends, setChickLegends] = useState([]);
    const [otherInfo, setOtherInfo] = useState({
        breedName: "",
        batchDuration: "",
        startDate: "",
        endDate: "",
        sexing: "",
        status: "",
        farmer: "",
        farm: "",
        batchName: "",
        company: "",
        numberofBirds: ""
    })
    const [resonseData, setResponseDate] = useState('');
    const onSubmit = async (data, e) => {
        e.preventDefault();
        setShowloader(true);
        try {
            const response = await axioInstance.getGrowthCurve(data.batchID);

            if (response.status == false) {
                alert('Hi')
                setShowData(false);
                setShowloader(false);
                setErrorMessage(response.message)
                return;
            }

            if (response.data.length == 0) {
                setShowData(false);
                setShowloader(false);
                setErrorMessage(response.message);
                return;
            }
            setShowData(false);
            setResponseDate(response)
            setData(response.data);
            setOtherInfo(response.others)
            const days = response.data.map((item) => item.day);
            setXaxis(days);
            setSexingStatus(response.others.sexing);
            let _data = response.data;
            //alert( moment(response.others.endDate).diff(moment(), 'days'))
            const daydiff = moment(response.others.endDate).diff(moment(), 'days');
            //alert(daydiff)
            if (daydiff > 0 && response.others.status == 'ACTIVE') {
                const day = response.others.batchDuration - daydiff;
                _data = _data.filter((item) => parseInt(item.day) <= day);
                //alert(JSON.stringify(_data));
            }
            setShowloader(false);
        } catch (err) {
            setShowData(false);
            setShowloader(false);
        }


    }
    const onBatchChange = (e) => {
        setShowGraph(false);
    }
    const getFarms = async () => {
        try {
            const response = await axioInstance.getListofFarms(getUserName());
            setFarmData(response.list)
        } catch (err) {
            console.log(err.message);

        }
    }
    const downloadReport = async () => {

        setShowloader2(true);
        try {
            console.log(otherInfo)
            const response = await axioInstance.downloadDopReport(resonseData)
            const url = window.URL.createObjectURL(new Blob([response]));
            // Create a link element to download the blob
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'dopreport.pdf'); // Set the filename for the download
            document.body.appendChild(link); // Append to the body
            link.click(); // Trigger the download
            document.body.removeChild(link); // Clean up
            setShowloader2(false);
        } catch (err) {
            console.log(err);
            setShowloader2(false);
            alert('Error in downloading report')
        }
    }
    useEffect(() => {
        getFarms();

    }, []);
    useEffect(() => {

        $(document).ready(function () {
            $('#selectFarm').select2();
            $('#selectFarm').on('change', function (e) {

                setValue('famrmID', e.target.value, { shouldValidate: true }); // Automatically triggers validation
                trigger('famrmID'); // Manually trigger validation for 'selectFarm'

                onchangeHandler(e)
            });
        });

        // Cleanup function to remove the event listener
        return () => {
            $('#selectFarm').off('change');
        };
    }, []);
    return (

        <div className="batchOperations">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Daily Operations Report</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="row">

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Select Barn</label>
                                    <select class="form-control" name="famrmID" id="selectFarm"
                                        {...register("famrmID", { required: true })}
                                    //onChange={onchangeHandler}
                                    >
                                        <option value="">-Select-</option>
                                        {farmData.map(item => (
                                            <option
                                                key={item.farmID}
                                                value={item.farmID}
                                            >
                                                {item.farmName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.famrmID && <span className="err-msg">Please select Barn</span>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Select Batch</label>
                                    <select class="form-control" name="batchID" onChange={onBatchChange}
                                        {...register("batchID", { required: true })}
                                    >
                                        <option value="">-Select-</option>
                                        {batchData.map(item => (
                                            <option
                                                key={item.batchID}
                                                value={item.batchID}
                                            >
                                                {item.batchName}

                                            </option>
                                        ))}
                                    </select>
                                    {errors.batchID && <span className="err-msg">Please select Batch</span>}
                                </div>
                            </div>
                            <div className="col-md-1 form-group">
                                <div class="spacer"></div>
                                <input type="submit" value="Fetch" class="btn btn-primary" />
                            </div>
                            <div className='col-md-1'>
                                {showLoader ?
                                    <section {...containerProps} style={{ "margin-top": "30px" }}>
                                        {indicatorEl}
                                    </section> : ""
                                }
                            </div>
                            <div className="col-md-4">
                                {showData ?
                                    <></>
                                    :
                                    <div className="downloadLink">
                                        <>
                                            {showLoader2 ?
                                                <section {...containerProps} style={{ "margin-top": "30px" }}>
                                                    {indicatorEl}
                                                </section> : <>
                                                    <span style={{ cursor: "pointer" }}
                                                    ><AiFillFilePdf size={28} color="red"></AiFillFilePdf></span> <span onClick={() => { downloadReport() }}>Download PDF</span>
                                                </>
                                            }
                                        </>

                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                    {
                        showData ?
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="redColor">{errorMessage}</p>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <table className="table table-striped table-sm">
                                            <tbody>
                                                <tr>
                                                    <td class="summaryHeader">Farmer</td>
                                                    <td>{otherInfo.farmer}</td>
                                                </tr>
                                                <tr>
                                                    <td class="summaryHeader">Company</td>
                                                    <td>{otherInfo.company == null || otherInfo.company == '' ? '--' : otherInfo.company}</td>
                                                </tr>
                                                <tr>
                                                    <td class="summaryHeader">Barn</td>
                                                    <td>{otherInfo.farm}</td>
                                                </tr>
                                                <tr>
                                                    <td class="summaryHeader">Start Date</td>
                                                    <td>{otherInfo.startDate}</td>
                                                </tr>
                                                <tr>
                                                    <td class="summaryHeader">Sexing</td>
                                                    <td>{otherInfo.sexing.toLocaleUpperCase()}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6">
                                        <table className="table table-striped table-sm">
                                            <tbody>
                                                <tr>
                                                    <td class="summaryHeader">Integrator</td>
                                                    <td>{otherInfo.integrator}</td>
                                                </tr>
                                                <tr>
                                                    <td class="summaryHeader"># of Birds</td>
                                                    <td>{otherInfo.numberofBirds}</td>
                                                </tr>
                                                <tr>
                                                    <td class="summaryHeader">Batch</td>
                                                    <td>{otherInfo.batchName}</td>
                                                </tr>
                                                <tr>
                                                    <td class="summaryHeader">Close Date</td>
                                                    <td>{otherInfo.endDate}</td>
                                                </tr>
                                                <tr>
                                                    <td class="summaryHeader">Type</td>
                                                    <td>{otherInfo.breedName}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {
                                    sexingStatus == 'no' ?

                                        <div className="row">
                                            <div className="col-md-12">
                                                <table class="dailyOperationsTable table table-striped table-sm table-bordered">
                                                    <tr>
                                                        <th rowspan="2">Day</th>
                                                        <th rowspan="2">Date</th>
                                                        <th rowspan="2">Feed (Kg) <br /> Total: 100</th>
                                                        <th colspan="3">Mortality (Total: {otherInfo.all_mortality})</th>
                                                        {/* <th rowspan="2">Recorded Weight (Kg)</th>
                                                        <th rowspan="2">Ideal Weight (Kg)</th>
                                                        <th rowspan="2">Variance (Actual vs Ideal)</th> */}
                                                        <th colspan="3">Recorded Weight-Male (Kg)</th>
                                                        <th rowspan="2">FCR</th>
                                                    </tr>
                                                    <tr>
                                                        <th class="">Natural</th>
                                                        <th>Culling</th>
                                                        <th>Total</th>
                                                        <th>Actual</th>
                                                        <th>Ideal</th>
                                                        <th>Variance</th>
                                                    </tr>
                                                    {data.map(item => (
                                                        <tr>
                                                            <td>{item.day}</td>
                                                            <td>{item.date}</td>
                                                            <td>{item.feed}</td>
                                                            <td>{item.mortality}</td>
                                                            <td>{item.culling}</td>
                                                            <td>{item.total}</td>
                                                            <td>{item.chickweight}</td>
                                                            <td>{item.ideal}</td>
                                                            <td>{item.variance}</td>
                                                            <td>{isNaN(item.fcr)? '--': item.fcr}</td>
                                                        </tr>
                                                    ))}
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table class="dailyOperationsTable table table-striped table-sm table-bordered">
                                                    <tr>
                                                        <th rowspan="2">Day</th>
                                                        <th rowspan="2">Date</th>
                                                        <th rowspan="2">Feed (Kg) <br /> Total: 100</th>
                                                        <th colspan="6">Mortality (Total: {otherInfo.all_mortality_sexing})</th>
                                                        <th colspan="3">Recorded Weight-Male (Kg)</th>
                                                        <th colspan="3">Recorded Weight-Female (Kg)</th>
                                                        <th colspan="2">FCR</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Natural Male</th>
                                                        <th>Culling Male</th>
                                                        <th>Total Male</th>
                                                        <th>Natural Female</th>
                                                        <th>Culling Female</th>
                                                        <th>Total Female</th>
                                                        
                                                        <th>Actual</th>
                                                        <th>Ideal</th>
                                                        <th>Variance</th>
                                                        
                                                        <th>Actual</th>
                                                        <th>Ideal</th>
                                                       
                                                        <th>Variance</th>
                                                        <th>Male</th>
                                                        <th>Female</th>
                                                    </tr>

                                                    {data.map(item => (
                                                        <tr>
                                                            <td>{item.day}</td>
                                                            <td>{item.date}</td>
                                                            <td>{item.feed}</td>
                                                            <td>{item.natural_male}</td>
                                                            <td>{item.culling_male}</td>
                                                            <td>{item.total_male}</td>
                                                            <td>{item.natural_female}</td>
                                                            <td>{item.culling_female}</td>
                                                            <td>{item.total_female}</td>
                                                            <td>{item.chickweight_male }</td>
                                                            <td>{item.ideal_male }</td>
                                                            <td
                                                                style={{background: 
                                                                    item.variance_male=='--'? '#888':
                                                                    item.variance_male >= item.ideal_male? '#216e00':
                                                                    item.variance_male <=0 ? '#a30202':
                                                                    (item.variance_male <item.chickweight_male && item.variance_male>0)? '#f29202'
                                                                    :'#888',
                                                                color: '#fff'}}
                                                            >{item.variance_male }</td>
                                                            <td>{item.chickweight_female }</td>
                                                            <td>{item.ideal_female }</td>
                                                            <td
                                                                style={{background: 
                                                                    item.variance_female=='--'? '#888':
                                                                    item.variance_female >= item.ideal_female? '#216e00':
                                                                    item.variance_female <=0 ? '#a30202':
                                                                    (item.variance_female <item.chickweight_female && item.variance_female>0)? '#f29202'
                                                                    :'#888',
                                                                color: '#fff'}}
                                                            >{item.variance_male }</td>
                                                            
                                                            <td>{isNaN(item.fcr_male)? '--': item.fcr_male}</td>
                                                            <td>{isNaN(item.fcr_female)?'--':item.fcr_female}</td>
                                                           
                                                        </tr>
                                                    ))}
                                                </table>


                                            </div>
                                        </div>
                                }
                            </div>

                    }
                </div>
            </div>
        </div>

    );
}

export default DailyOperationsReport;