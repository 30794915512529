import React, { useEffect, useState, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import axios from 'axios';
import { env } from './const';
import { headers } from '../utils/common';
import { getRole, getUserName, controls } from '../utils/common';
import { useLoading, Bars } from '@agney/react-loading';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { useLocation } from 'react-router-dom';

import $ from 'jquery';
import 'select2';
import * as axiosInstance from '../utils/axiosinstace';
import { Alert } from "react-bootstrap";
const ControlSystems = (props) => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />,
    });
    const location = useLocation();

    const [role] = useState(getRole())
    const [data, setData] = useState([])
    const [headersobj] = useState(headers());
    const [farmData, setFarmData] = useState([]);

    const {
        register,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        reset,
        setValue,
        getValues,
        trigger,
        formState: { errors },
    } = useForm();
    const [showResults, setShowResults] = useState(false);
    const [resultMessage, setResultMessage] = useState({});
    const [showloader, setShowloader] = useState(false);

    const onSubmit = async (data) => {
        console.log(data)
        try {
            data.automatedMode = data.automationMode;
            const response = await axiosInstance.controlsystem(data);
            // console.log(res.data.message);
            setShowResults(true);
            setShowloader(false);
            if (response.status) {
                setResultMessage({
                    error: false, message: response.message
                });
                setControlSystemStatus('run')
            } else {
                setResultMessage({
                    error: true, message: response.message
                });
            }

        } catch (err) {
            setShowResults(true);
            setResultMessage({
                error: true, message: err.response.data.message
            });
            setShowloader(false);
        }
    };

    // Generate time options
    const generateTimeOptions = () => {
        const options = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                const displayTime = `${hour % 12 === 0 ? 12 : hour % 12}:${minute.toString().padStart(2, '0')} ${hour < 12 ? 'AM' : 'PM'}`;
                options.push(<option key={time} value={time}>{displayTime}</option>);
            }
        }
        return options;
    };
    const initialFormState = {
        selectFarm: '',
        mode: '',
        automationMode: '', // Ensure this is set to hide conditional fields
        startTime: '',
        endTime: '',
        onTemperature: '',
        offTemperature: '',
        runTime: '',
        pauseTime: '',
    };
    const farmSelection = watch('selectFarm');
    const modeSelection = watch('mode');
    const automationMode = watch('automationMode');
    // Reset the form to initial state
    const handleReset = () => {
        reset(initialFormState);
        $('#selectFarm').val('').trigger('change');
    };
    const getFarms = async () => {
        try {
            const response = await axiosInstance.getListofFarms(getUserName());
            setFarmData(response.list)
        } catch (err) {
            console.log(err.message);
        }
    }

    const [selectedFarm, setSelectedfarm] = useState('');

    useEffect(() => {
        getFarms();
        $(document).ready(function () {
            $('#selectFarm').select2();
            $('#selectFarm').on('change', function (e) {
                // When the select2 field changes, update react-hook-form
                //setValue('selectFarm', e.target.value);
                onFarmChangeHandler(e.target.value);
                setValue('selectFarm', e.target.value, { shouldValidate: true }); // Automatically triggers validation
                trigger('selectFarm'); // Manually trigger validation for 'selectFarm'
                getControlSystemData(e.target.value);
                
            });
        });

        // Cleanup function to remove the event listener
        return () => {
            $('#selectFarm').off('change');
        };
    }, [reset]);
    const [selectedMode, setSelectedMode] = useState('');
    const handleModeChange = (e) => {
        setSelectedMode(e.target.value)
        const mode = e.target.value;
        if (mode == 'manual') {
            getFaninfo();
        }
    }
    const [fans, setFans] = useState([]);
    const getFaninfo = async () => {
        setShowloader(true);
        console.log(selectedFarm);
        try {
            const response = await axiosInstance.getFansByFarm(selectedFarm);
            console.log(response)
            if (response.list.length > 0) {
                const highestFan = response.list.reduce((acc, item, index) => {
                    if (acc.fanID > item.fanID) {
                        return acc; // Keeps the current highest fan which includes the index
                    } else {
                        return {...item, index: index}; // Updates to the new highest fan and adds the index to the fan object
                    }
                }, {...response.list[0], index: 0}); // Initialize with the first item and index 0
            
                console.log("Highest fan with index:", highestFan);
                setFans([highestFan]); // Pass the fan object which now includes the index
            }
            setShowloader(false);
        } catch (error) {
            //console.log(err.message);
            setShowloader(false);
        }
    }
    const onFarmChangeHandler = (farmID) => {
        setSelectedfarm(farmID);
       
    }
    const [isLoading, setIsLoading] = useState({});
    const [activeFarmID, setActiveFarmID] = useState(null);

    const handleToggle = async (itemId, fanID, checked) => {
        setIsLoading(prevState => ({
            ...prevState,
            [itemId]: true
        }));
        const status = checked ? 'ACTIVE' : 'INACTIVE';

        const updatedData = fans.map((item) =>
            item.fanID === fanID ? { ...item, swtichStatus: status } : item
        );

        // Update Fan swith status
        const data = { fanID: fanID, fanNumber: itemId + 1, status: status };
        console.log(data)
        try {
            const response = await axiosInstance.updateFanStatus(data);
            if (!response.message) {
                alert("Error in controlling switch");
                return;
            }
            setIsLoading(prevState => ({
                ...prevState,
                [itemId]: false
            }));
        } catch (err) {
            alert(err.response.data.message);
            return;
        }
        setFans(updatedData);
        if (checked) {
            setActiveFarmID(fanID);
        } else {
            setActiveFarmID(null);
        }
    };
    const [showRun, setShowRun] = useState(false);
    const [schedulerStatus, setSchedulerStatus] = useState('');
    const [controlSystemStatus, setControlSystemStatus] = useState('')
    const getControlSystemData = async(farmID)=>{
        setShowResults(false);
        setSelectedfarm(farmID);
        try {
            const response = await axiosInstance.getControlSystemData(farmID);
            if(response.list.length >0) {
               
                setShowRun(true);
                setSchedulerStatus(response.list[0].status);
                reset({
                    selectFarm: response.list[0].farmID,
                    startTime: response.list[0].startTime,
                    endTime: response.list[0].endTime,
                    onTemperature: response.list[0].onTemp,
                    offTemperature: response.list[0].offTemp,
                    runTime: response.list[0].runTime,
                    pauseTime: response.list[0].pauseTime,
                    
                }) 
                setValue('mode', 'automation', {
                    shouldTouch: true,
                    shouldDirty: true,
                })
                setSelectedMode('automation')
                setControlSystemStatus(response.list[0].status);
                setValue('automationMode',response.list[0].automationMode, {
                    shouldTouch: true,
                    shouldDirty: true,
                })
            } else {
                setValue('mode', '', {
                    shouldTouch: true,
                    shouldDirty: true,
                })
                setValue('automationMode', '', {
                    shouldTouch: true,
                    shouldDirty: true,
                })
                setSelectedMode('');
                setValue('startTime', '');
                setValue('endTime', '');
                setValue('onTemperature', '');
                setValue('offTemperature', '');
                setValue('runTime', '');
                setValue('pauseTime', '');
            }
        } catch (err) {
            console.log(err.message);
        }
    }
    const stopScheduler = async(e)=>{
        e.preventDefault();
        const isConfirmed = window.confirm('Are you sure you want stop the scheduler?');
        // alert(getValues('selectFarm'))
        try {
            
            if(isConfirmed) {
                let data = {
                    farmID: getValues('selectFarm'),
                    status: 'stopped'
                }
                console.log(data)
                const response = await axiosInstance.updateConstrolSystemStatus(data);
                if (!response.status) {
                    alert(response.message);
                    return;
                } else {
                    alert(response.message);
                    setControlSystemStatus('stopped')
                }
            } else {
                return;
            }
        } catch (err) {
            alert(err.message);
            return;
        }
    }
    return (
        <div className="batchOperations">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Manage Cooling Pads</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            {showResults ? <Results key={Math.random()} message={resultMessage.message} error={resultMessage.error} /> : null}
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="selectFarm" className="form-label">Select Farm:</label>
                                    <select
                                        id="selectFarm"

                                        {...register('selectFarm', { required: true })} className="form-control form-control-sm">
                                        <option value="">-Select-</option>
                                        {farmData.map(item => (
                                            <option
                                                key={item.farmID}
                                                value={item.farmID}
                                            >
                                                {item.farmName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.selectFarm && <div className="err-msg">Selecting a farm is required.</div>}
                                </div>
                            </div>
                        </div>
                        {
                            farmSelection == '' ? <></> :
                                <div className="row">
                                    <div className="col-md-4">
                                        <span><strong>Select Mode: </strong>&nbsp; &nbsp;</span>
                                        <div class="form-check form-check-inline">
                                            <input className="form-check-input"
                                                type="radio" id="manualMode"
                                                value="manual" {...register('mode', { required: true })}
                                                onChange={handleModeChange}
                                                disabled={controlSystemStatus=='run'?true:false}
                                            />
                                            <label class="form-check-label" for="manualMode">
                                                Manual
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input class="form-check-input"
                                                type="radio" id="automationMode"
                                                value="automation" {...register('mode', { required: true })}
                                                onChange={handleModeChange}
                                            />
                                            <label class="form-check-label" for="automationMode">
                                                Automation
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        {showloader ?
                                            <section {...containerProps} style={{ "margin-top": "30px" }}>
                                                {indicatorEl} {/* renders only while loading */}
                                            </section> : ""
                                        }
                                    </div>
                                </div>
                        }
                        {
                            selectedMode === 'manual' ? (
                                <div style={{ marginTop: 20 }}>
                                    {fans.map((item, index) => (
                                        <div className="row" key={index} style={{ marginBottom: 15 }}>
                                            <div className="col-md-2">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        {
                                                            item.swtichStatus === 'ACTIVE' ?
                                                                <div className="heading-img">
                                                                    <img src="/dist/img/fan.png" className='rotating'></img>
                                                                </div>
                                                                : <div className="heading-img">
                                                                    <img src="/dist/img/fan_grey.png"></img>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="col-md-8" style={{ marginTop: 6 }}>
                                                        <span className="fanLabel">Cooling pad</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-1" style={{ marginTop: 15 }}>
                                                <BootstrapSwitchButton
                                                    checked={item.swtichStatus === 'ACTIVE' ? true : false}
                                                    disabled={activeFarmID !== null && activeFarmID !== item.fanID}
                                                    onChange={(checked) => {
                                                        //swithcontrol(checked, item.farmID);
                                                        // setShowOnLoader(true);
                                                        handleToggle(item.index, item.fanID, checked)
                                                    }}
                                                    width={80}
                                                />
                                            </div>
                                            <div className='col-md-1' style={{ marginLeft: 10 }}>
                                                {isLoading[index] && (
                                                    <section {...containerProps} style={{ "margin-top": "0px" }}>
                                                        {indicatorEl} {/* renders only while loading */}
                                                    </section>
                                                )}
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            ) : selectedMode === 'automation' ?
                                <div className="row" style={{ marginTop: 20 }}>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="automatedMode" className="form-label">Automation Mode:</label>
                                            <select {...register('automationMode', { required: true })} className="form-control form-control-sm">
                                                <option value="">-Select-</option>
                                                <option value="Threshold">Threshold</option>
                                                <option value="Timer">Timer</option>
                                            </select>
                                            {errors.automationMode && <div className="err-msg">Selecting an automated mode is required.</div>}
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            farmSelection !== '' && selectedMode == 'automation' && automationMode !== '' ?
                                <div className="row" >
                                    <div className="col-md-4" style={{
                                        marginBottom: 10, padding: 5,
                                        borderBottom: '1px solid #ddd', borderTop: '1px solid #ddd', background: '#f1f1f1'
                                    }}>
                                        <strong>Selected Mode: </strong> {automationMode}
                                    </div>
                                </div> : <></>
                        }
                        {selectedMode == 'automation' && automationMode === 'Threshold' && (
                            <>
                                <div className="row">
                                    <div className="col-md-2">
                                        <label htmlFor="startTime" className="form-label">Start Time:</label>
                                        <select {...register('startTime', { required: 'Start Time is required' })} className="form-control form-control-sm">
                                            <option value="">-Select-</option>
                                            {generateTimeOptions()}
                                        </select>
                                        {errors.startTime && <div className="err-msg">Start time is required.</div>}
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="endTime" className="form-label">End Time:</label>
                                        <select
                                            {...register('endTime', { required: "End time is required" })}
                                            className={`form-control form-control-sm`}
                                        >
                                            <option value="">-Select-</option>
                                            {generateTimeOptions()}
                                        </select>
                                        {errors.endTime && <div className="err-msg">End time is required.</div>}
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: 20 }}>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="onTemperature" className="form-label">On Temperature (°C):</label>
                                            <input type="number"
                                                {...register('onTemperature',
                                                    {
                                                        required: 'Required',
                                                        min: { value: 1, message: 'Should not be less than 0' },
                                                        max: { value: 100, message: 'Should not be less than 100' }
                                                    })
                                                }
                                                className="form-control form-control-sm" />
                                            {errors.onTemperature && <div className="err-msg">On Temperature must be between 0 and 100.</div>}
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="offTemperature" className="form-label">Off Temperature (°C):</label>
                                            <input type="number"

                                                {...register('offTemperature',
                                                    {
                                                        required: 'Required',
                                                        min: { value: 1, message: 'Should not be less than 0' },
                                                        max: { value: 100, message: 'Should not be less than 100' }
                                                    })
                                                } className="form-control form-control-sm" />
                                            {errors.offTemperature && <div className="err-msg">Off Temperature must be between 0 and 100.</div>}
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}
                        {selectedMode == 'automation' && automationMode === 'Timer' && (
                            <>
                                <div className="row">
                                    <div className="col-md-2">
                                        <label htmlFor="startTime" className="form-label">Start Time:</label>
                                        <select {...register('startTime', { required: 'Start Time is required' })} className="form-control form-control-sm">
                                            <option value="">-Select-</option>
                                            {generateTimeOptions()}
                                        </select>
                                        {errors.startTime && <div className="err-msg">Start time is required.</div>}
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="endTime" className="form-label">End Time:</label>
                                        <select
                                            {...register('endTime', { required: "End time is required" })}
                                            className={`form-control form-control-sm`}
                                        >
                                            <option value="">-Select-</option>
                                            {generateTimeOptions()}
                                        </select>
                                        {errors.endTime && <div className="err-msg">End time is required.</div>}
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: 20 }}>

                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="runTime" className="form-label">Run Time:</label>
                                            <input type="number"
                                                {...register("runTime", {
                                                    required: "Enter Value",
                                                    min: { value: 1, message: 'Should not be less than 0' },

                                                })}
                                                className="form-control form-control-sm" />
                                            {errors.runTime && <div className="err-msg"></div>}
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label htmlFor="pauseTime" className="form-label">Pause Time:</label>
                                            <input type="number"
                                                {...register("pauseTime", {
                                                    required: "Enter Value",
                                                    min: { value: 1, message: 'Should not be less than 0' },

                                                })}
                                                className="form-control form-control-sm" />
                                            {errors.pauseTime && <div className="err-msg"></div>}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {farmSelection !== '' && selectedMode == 'automation' && automationMode !== '' && (
                            <div className="row">
                               
                                <div className="col-md-12">
                                    {
                                        controlSystemStatus!=='run'?
                                        <input type="submit" value="Save & Run" class="btn btn-primary newBtn" />
                                        :
                                        <>
                                         <a class="btn btn-danger newBtn" onClick={(e)=>stopScheduler(e)} style={{color:'#fff'}}>Stop Scheduler</a>
                                        <p style={{ marginTop: 10}}>To control cooling pads manually, please stop the scheduler and select Manual mode</p>
                                        </>
                                    }
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}
const Results = (props) => {
    let error, classes;
    if (props.error === false) {
        error = 'Success';
        classes = 'alert alert-success alert-dismissible fade show'
    } else {
        error = 'Error!';
        classes = 'alert alert-danger alert-dismissible fade show'
    }
    return (
        <div className="results">
            <div className={classes}>
                <strong>{error}</strong> {props.message}

                <button type="button" class="close" data-dismiss="alert">&times;</button>
            </div>
        </div>
    );
}
export default ControlSystems;